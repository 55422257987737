import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "gatsby";

import { isBrowser } from "../utils";
import { defaultLanguage } from "../i18n";

const CategoryPosts = ({ category }) => {
  const posts = useMemo(() => {
    return !category ? [] : category?.posts?.nodes;
  }, [category]);
  const nameCategory = category?.name;
  const slugCategory = category?.slug;

  const [listPosts, setListPosts] = useState([...posts?.slice(0, 5)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(posts?.length > 5);
  const loadRef = useRef();

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  const intl = useIntl();

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  });

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = listPosts.length;
      const isMore = currentLength < posts.length;
      const nextResults = isMore
        ? posts.slice(currentLength, currentLength + 5)
        : [];
      setListPosts([...listPosts, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, listPosts, posts]);

  useEffect(() => {
    const isMore = listPosts.length < posts.length;
    setHasMore(isMore);
  }, [listPosts, posts]);

  // Need statement for gatsby build as window undefined
  if (!isBrowser()) {
    return null;
  }

  return (
    <>
      <h1 className="category-post_title">{category.name}</h1>
      <div className="wrapper_category-posts section">
        {_.map(listPosts, (post, i) => {
          const { id, excerpt, title, featuredImage, seo, originalSlug } = post;
          const locale =
            defaultLanguage === intl.locale ? "/" : `/${intl.locale}/`;
            
          return (
            <React.Fragment key={i}>
              <Seo 
              post={seo}
              title={category.name}
               />
              <div className="category-post" key={id}>
                <div className="post-img">
                  <img
                    data-src={featuredImage?.node?.mediaItemUrl}
                    alt={featuredImage?.node?.title}
                    className="lazyload"
                  />
                </div>
                <div className="post-content">
                  <h2>
                    <Link href={`${locale}${originalSlug}`}>{title}</Link>
                  </h2>
                  <p>
                    <Link href={`${locale}category/${slugCategory}`}>{nameCategory}</Link>
                  </p>
                  <div className="post-content_text">
                    <p>
                      {excerpt.length >= 200
                        ? `${excerpt.slice(3, 200)}...`
                        : excerpt.slice(3, -4)}
                    </p>
                  </div>
                  <button>
                    <a href={`${locale}${originalSlug}`}>
                      <FormattedMessage
                        id="read_more"
                        defaultMessage="Read more"
                      />
                    </a>
                  </button>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <div ref={loadRef} className="loadContent">
          {hasMore ? (
            <p>
              <FormattedMessage id="loading" defaultMessage="Loading..." />
            </p>
          ) : (
            <p>
              <FormattedMessage
                id="no_more_results"
                defaultMessage="No more results"
              />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryPosts;
